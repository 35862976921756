import {FedopsInteractions, FieldMasks, SPECS} from '../../components/Checkout/constants';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {
  createOrderAndCharge,
  getCheckout,
  removeCoupon,
  removeGiftCard,
  removeLineItems,
  updateCheckout,
} from '@wix/ambassador-ecom-v1-checkout/http';
import {subscribe, unsubscribe} from '@wix/ambassador-ecom-v1-subscribe-request/http';
import {UpdateCheckoutRequest} from '@wix/ambassador-ecom-v1-checkout/types';
import {HttpError} from '@wix/http-client';
import {CheckoutModel} from '../models/Checkout.model';
import {CheckoutErrorCode} from '../utils/errors';
import {CheckoutErrorModel} from '../models/CheckoutError.model';
import {BIService} from './BIService';
import {NavigationService} from './NavigationService';
import {CheckoutGraphql, PlaceOrderUrlParams} from '../../types/app.types';
import {ambassadorWithHeaders} from '../utils/ambassador.utils';
import {
  ApiAddressFragment,
  CheckoutFragment,
  CustomFieldFragment,
  FullAddressContactDetailsFragment,
  MultiCurrencyPriceFragment,
} from '../../gql/graphql';
import {AutoGraphqlApi} from '../apis/AutoGraphqlApi';
import {PaymentError} from '../../types/payment.types';

export interface MinimumOrderErrorData {
  minimumOrderAmount: MultiCurrencyPriceFragment;
  remaining: MultiCurrencyPriceFragment;
}

export class CheckoutService {
  private readonly checkoutId?: string;
  private readonly flowAPI: ControllerFlowAPI;
  private readonly siteStore: SiteStore;
  private readonly navigationService: NavigationService;
  private readonly biService: BIService;
  public readonly currency?: string;
  private originalShippingOptionTitle: string = '';
  private readonly autoGraphqlApi: AutoGraphqlApi;

  public checkout!: CheckoutModel;
  public placeOrderError?: CheckoutErrorModel;
  public updateCheckoutError?: CheckoutErrorModel;
  public applyCouponError?: CheckoutErrorModel;
  public applyGiftCardError?: CheckoutErrorModel;

  constructor({
    flowAPI,
    siteStore,
    biService,
    navigationService,
    currency,
  }: {
    flowAPI: ControllerFlowAPI;
    siteStore: SiteStore;
    biService: BIService;
    navigationService: NavigationService;
    currency?: string;
  }) {
    this.flowAPI = flowAPI;
    this.biService = biService;
    this.siteStore = siteStore;
    this.currency = currency;
    this.navigationService = navigationService;
    this.checkoutId = this.navigationService.checkoutId;
    this.autoGraphqlApi = new AutoGraphqlApi({flowAPI, siteStore, currency});
  }

  public async init(): Promise<void> {
    this.flowAPI.fedops.interactionStarted(FedopsInteractions.FetchCheckout);
    await this.fetchCheckout();
    this.flowAPI.fedops.interactionEnded(FedopsInteractions.FetchCheckout);
    this.originalShippingOptionTitle = this.checkout.selectedShippingOption?.title ?? '';
  }

  public async fetchCheckout(): Promise<void> {
    if (!this.checkoutId) {
      console.error('No checkoutId in appSectionParams');
      throw new Error('no checkout id');
    }

    const useAutoGraphqlApi = this.siteStore.experiments.enabled(SPECS.UseAutoGraphqlApi);
    const {data} = useAutoGraphqlApi
      ? await this.autoGraphqlApi.getCheckout(this.checkoutId)
      : await ambassadorWithHeaders(getCheckout({id: this.checkoutId}), this.siteStore, this.flowAPI, this.currency);

    /* istanbul ignore next */
    if (data.checkout) {
      this.setCheckout(data.checkout as CheckoutGraphql);
    } else {
      /* istanbul ignore next */
      console.error('No checkout data from the API');
      /* istanbul ignore next */
      throw new Error('No checkout data');
    }
  }

  public async createOrderAndCharge(
    paymentDetailsId: string | undefined,
    urlParams: PlaceOrderUrlParams
  ): Promise<{orderId?: string | null; paymentResponseToken?: string | null} | undefined> {
    try {
      const useAutoGraphqlApi = this.siteStore.experiments.enabled(SPECS.UseAutoGraphqlApi);
      const variables = {
        id: this.checkout.id,
        paymentToken: paymentDetailsId ?? this.navigationService.cashierPaymentId,
        urlParams,
      };
      const {data} = useAutoGraphqlApi
        ? await this.autoGraphqlApi.createOrderAndCharge(variables)
        : await ambassadorWithHeaders(createOrderAndCharge(variables), this.siteStore, this.flowAPI, this.currency);
      return data;
    } catch (error) {
      return this.handlePlaceOrderError(error as HttpError);
    }
  }

  private handlePlaceOrderError(error: HttpError): {orderId: string} | undefined {
    const errorModel = CheckoutErrorModel.fromHttpError(error);
    if (errorModel.code === CheckoutErrorCode.CHECKOUT_ALREADY_PAID) {
      return {orderId: errorModel.data?.orderId ?? errorModel.data?.subscriptionId};
    }
    this.placeOrderError = errorModel;

    if (this.placeOrderError.code === CheckoutErrorCode.GENERAL_ERROR) {
      this.biService.checkoutErrorTrackingForDevelopers(
        JSON.stringify(error?.response?.data),
        JSON.stringify(this.placeOrderError.data)
      );
    }
  }

  public async applyCoupon(couponCode: string): Promise<void> {
    const useAutoGraphqlApi = this.siteStore.experiments.enabled(SPECS.UseAutoGraphqlApi);
    try {
      const updatePayload = {
        checkout: {
          id: this.checkoutId,
        },
        couponCode,
      };
      const {data} = useAutoGraphqlApi
        ? await this.autoGraphqlApi.updateCheckout(updatePayload)
        : await ambassadorWithHeaders(updateCheckout(updatePayload), this.siteStore, this.flowAPI, this.currency);
      this.setCheckout(data.checkout as CheckoutGraphql);
      this.biService.couponApplied(this.checkout);
    } catch (error) {
      this.applyCouponError = CheckoutErrorModel.fromHttpError(error as HttpError);
      this.biService.errorWhenApplyingACoupon(couponCode, this.applyCouponError, this.checkout);
    }
  }

  public async removeCoupon(): Promise<void> {
    if (this.checkout.appliedCoupon?.code) {
      const useAutoGraphqlApi = this.siteStore.experiments.enabled(SPECS.UseAutoGraphqlApi);
      this.biService.removeACoupon(this.checkout);
      const removeCouponPayload = {
        id: this.checkout.id,
      };
      const {data} = useAutoGraphqlApi
        ? await this.autoGraphqlApi.removeCoupon(removeCouponPayload)
        : await ambassadorWithHeaders(removeCoupon(removeCouponPayload), this.siteStore, this.flowAPI, this.currency);
      this.setCheckout(data.checkout as CheckoutGraphql);
    }
    this.applyCouponError = undefined;
  }

  public async applyGiftCard(giftCardCode: string): Promise<void> {
    try {
      const useAutoGraphqlApi = this.siteStore.experiments.enabled(SPECS.UseAutoGraphqlApi);
      const updatePayload = {
        checkout: {
          id: this.checkout.id,
        },
        giftCardCode,
      };
      const {data} = useAutoGraphqlApi
        ? await this.autoGraphqlApi.updateCheckout(updatePayload)
        : await ambassadorWithHeaders(updateCheckout(updatePayload), this.siteStore, this.flowAPI, this.currency);
      this.setCheckout(data.checkout as CheckoutGraphql);
      this.biService.giftCardCheckoutCodeApplied(this.checkout);
    } catch (error) {
      this.applyGiftCardError = CheckoutErrorModel.fromHttpError(error as HttpError);
      this.biService.checkoutErrorWhenApplyingAGiftCard(this.applyGiftCardError, this.checkout);
    }
  }

  public async removeGiftCard(): Promise<void> {
    if (this.checkout.giftCard?.obfuscatedCode) {
      const useAutoGraphqlApi = this.siteStore.experiments.enabled(SPECS.UseAutoGraphqlApi);
      this.biService.giftCardCheckoutRemoveCode(this.checkout);
      const removePayload = {
        id: this.checkout.id,
      };
      const {data} = useAutoGraphqlApi
        ? await this.autoGraphqlApi.removeGiftCard(removePayload)
        : await ambassadorWithHeaders(removeGiftCard(removePayload), this.siteStore, this.flowAPI, this.currency);
      this.setCheckout(data.checkout as CheckoutGraphql);
    }
    this.applyGiftCardError = undefined;
  }

  public async subscribe(): Promise<void> {
    await ambassadorWithHeaders(
      subscribe({
        email: this.checkout.buyerInfo.email,
      }),
      this.siteStore,
      this.flowAPI,
      this.currency
    );
  }

  public async unsubscribe(): Promise<void> {
    await ambassadorWithHeaders(
      unsubscribe({
        email: this.checkout.buyerInfo.email,
      }),
      this.siteStore,
      this.flowAPI,
      this.currency
    );
  }

  public async setBillingDetails({
    contactDetails,
    address,
    addressesServiceId,
  }: {
    contactDetails: FullAddressContactDetailsFragment;
    address?: ApiAddressFragment;
    addressesServiceId?: string;
  }): Promise<void> {
    return this.updateCheckout(
      {
        billingInfo: {
          contactDetails,
          ...(address ? {address} : /* istanbul ignore next */ {}),
          ...(addressesServiceId ? {addressesServiceId} : {}),
        },
      },
      [
        FieldMasks.billingContact,
        ...(address ? [FieldMasks.billingAddress] : /* istanbul ignore next */ []),
        ...(addressesServiceId ? [FieldMasks.billingAddressesServiceId] : []),
      ]
    );
  }

  public async setShippingInfo({
    contactDetails,
    email,
    customField,
    shippingAddress,
    addressesServiceId,
  }: {
    contactDetails: FullAddressContactDetailsFragment;
    email?: string;
    customField?: CustomFieldFragment;
    shippingAddress?: ApiAddressFragment;
    addressesServiceId?: string;
  }): Promise<void> {
    return this.updateCheckout(
      {
        buyerInfo: {email},
        shippingInfo: {
          shippingDestination: shippingAddress
            ? {contactDetails, address: shippingAddress, addressesServiceId}
            : {contactDetails, addressesServiceId},
        },
        ...(customField ? {customFields: [customField]} : {}),
      },
      [
        FieldMasks.shippingContact,
        FieldMasks.buyerInfoEmail,
        FieldMasks.shippingAddressesServiceId,
        ...(shippingAddress ? [FieldMasks.shippingAddress] : []),
        ...(customField ? [FieldMasks.customField] : []),
      ]
    );
  }

  public async setCustomField(customField: CustomFieldFragment): Promise<void> {
    return this.updateCheckout({customFields: [customField]}, [FieldMasks.customField]);
  }

  public async setBillingAddress(billingAddress: ApiAddressFragment): Promise<void> {
    return this.updateCheckout({billingInfo: {address: billingAddress}}, [FieldMasks.billingAddress]);
  }

  public async setSingleAddress(address: ApiAddressFragment): Promise<void> {
    return this.updateCheckout({billingInfo: {address}, shippingInfo: {shippingDestination: {address}}}, [
      FieldMasks.billingAddress,
      FieldMasks.shippingAddress,
    ]);
  }

  public async setShippingOption(shippingOptionId: string): Promise<void> {
    return this.updateCheckout({shippingInfo: {selectedCarrierServiceOption: {code: shippingOptionId}}}, [
      FieldMasks.selectedCarrierServiceOption,
    ]);
  }

  private async updateCheckout(
    checkout: Partial<Omit<CheckoutFragment, 'id'>>,
    fieldMask: FieldMasks[]
  ): Promise<void> {
    try {
      const useAutoGraphqlApi = this.siteStore.experiments.enabled(SPECS.UseAutoGraphqlApi);
      const updatePayload = {
        checkout: {
          id: this.checkout.id,
          ...checkout,
        },
        fieldMask,
      };
      const {data} = useAutoGraphqlApi
        ? await this.autoGraphqlApi.updateCheckout(updatePayload)
        : await ambassadorWithHeaders(
            updateCheckout(updatePayload as UpdateCheckoutRequest),
            this.siteStore,
            this.flowAPI,
            this.currency
          );
      this.setCheckout(data.checkout as CheckoutGraphql);
    } catch (error) {
      this.updateCheckoutError = CheckoutErrorModel.fromHttpError(error as HttpError);
    }
  }

  public async removeLineItem(lineItemId: string): Promise<void> {
    const useAutoGraphqlApi = this.siteStore.experiments.enabled(SPECS.UseAutoGraphqlApi);
    const removePayload = {
      id: this.checkout.id,
      lineItemIds: [lineItemId],
    };
    const {data} = useAutoGraphqlApi
      ? await this.autoGraphqlApi.removeLineItem(removePayload)
      : await ambassadorWithHeaders(removeLineItems(removePayload), this.siteStore, this.flowAPI, this.currency);
    this.setCheckout(data.checkout as CheckoutGraphql);
  }

  public clearPlaceOrderError(): void {
    this.placeOrderError = undefined;
  }

  /* istanbul ignore next */
  public setPlaceOrderPaymentError(paymentError: PaymentError): void {
    /* istanbul ignore next */
    this.placeOrderError = CheckoutErrorModel.fromPaymentError(paymentError);
  }
  public clearUpdateCheckoutError(): void {
    this.updateCheckoutError = undefined;
  }

  public get originalShippingTitle(): string {
    return this.originalShippingOptionTitle;
  }

  private setCheckout(checkout: CheckoutGraphql) {
    this.checkout = new CheckoutModel(checkout);
  }
}
